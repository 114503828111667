<template>
  <div>
    <v-container class="pt-0 pt-sm-3 app-fill-height mobile-container">
      <v-container tag="section" class="pt-0 pt-sm-3 mb-10 mobile-container">
        <v-responsive          
          max-width="1200px"
          class="mx-auto"
        >
            <div>
              <h1 class="htest-h1 mt-sm-10 mt-5 text-center">
                Выгодные наборы для старта со скидкой до 50%
              </h1>
              <div>
                <p class="mt-5 p-18 text-center">
                  Только при регистрации ты можешь приобрести наборы с
                  максимальной скидкой
                </p>
              </div>
              <section id="prof-start" class="mt-10">
                <h2 class="h2-start">Выгодные наборы</h2>
                <div class="px-2">
                  <h3 class="h3-start text-center">Ты получаешь:</h3>
                  <!-- <p class="p-title text-center">Набор печатных материалов</p>
                  <p class="text-center">
                    каталог, карьерный план, презентация и др.
                  </p>
                  <div class="prof-start-img-books">
                    <img
                      src="/img/start/first-order-books.png"
                      alt="Печатные материалы"
                    />
                  </div> -->
                  <p class="p-title text-center mt-10 mb-7">
                    Один из наборов на выбор
                  </p>
                  <v-expansion-panels class="exp-start" popout>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="epx-title">
                        Набор "Анти-эйдж"
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="anti-age-start-img">
                          <img
                            src="/img/start/anti-age-x1.jpg"
                            alt="Набор Анти-эйдж"
                          />
                        </div>
                        <p>
                          Нормализует обмен веществ, дает больше энергии,
                          укрепляет здоровье, восстанавливает работу ЖКТ
                        </p>
                        <p class="p-title-green text-center mt-3 mb-1">
                          Состав набора:
                        </p>
                        <ul class="ul-exp">
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Питьевой гель Алоэ Вера Персик, 3 шт.
                            <p class="p-profit">
                              (1890 руб. за шт. вместо 3665, выгода - 1775 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Антистресс напиток Майнд Мастер "Красная формула", 3
                            шт.
                            <p class="p-profit">
                              (950 руб. за шт. вместо 1709, выгода - 759 руб. за
                              шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Антистресс напиток Майнд Мастер "Зеленая формула", 2
                            шт.
                            <p class="p-profit">
                              (950 руб. за шт. вместо 1709, выгода - 759 руб. за
                              шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Минеральный комплекс "Пробаланс", 1 шт.
                            <p class="p-profit">
                              (1950 руб. за шт. вместо 3725, выгода - 1775 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Капсулы Супер Омега, 1 шт.
                            <p class="p-profit">
                              (2349 руб. за шт. вместо 4235, выгода - 1886 руб.
                              за шт.)
                            </p>
                          </li>
                        </ul>
                        <p class="mt-5 mb-3">
                          <a
                            href="/anti-age"
                            target="_blanc"
                            class="a-green"
                          >
                            Подробнее о наборе "Анти-эйдж"
                          </a>
                        </p>
                        <p class="mt-10 mb-3 text-center p-special">
                          Спец. цена:
                        </p>
                        <v-divider
                          class="start-divider mx-auto"
                          color="#baa334"
                          length="195px"
                          thickness="3"
                        />
                        <p class="start-price text-center">14 719 руб.</p>
                        <v-divider
                          class="start-divider mx-auto"
                          color="#baa334"
                          length="195px"
                          thickness="3"
                        />
                        <p class="text-center p-old mt-3">
                          Вместо:
                          <span>
                            27 500
                            <img
                              src="/img/red-double.png"
                              class="red-double-img"
                            />
                          </span>
                        </p>
                        <p class="text-center p-old mt-1">
                          Выгода:
                          <span>
                            12 781
                            <img
                              src="/img/red-bottom.png"
                              class="red-bottom-img"
                            />
                          </span>
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="epx-title">
                        Набор "Обмен веществ"
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="metabolism-start-img">
                          <img
                            src="/img/start/metabolism-x1.jpg"
                            alt="Набор Обмен веществ"
                            class="mr-3"
                          />
                        </div>
                        <p>
                          Комплексное решение, которое поможет нормализовать
                          обмен веществ, работу ЖКТ, кислотно-щелочной баланс,
                          укрепит иммунитет, восстановит работу внутренних
                          органов, иммунной, эндокринной системы, защитит от
                          простудных заболеваний и от стресса.
                        </p>
                        <p class="p-title-green text-center mt-5 mb-1">
                          Состав набора:
                        </p>
                        <ul class="ul-exp">
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>                           
                            Питьевой гель Алоэ Вера Персик или Мед, 3 шт.
                            <p class="p-profit">
                              (1890 руб. за шт. вместо 3665, выгода - 1775 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Минеральный комплекс "Пробаланс", 1 шт.
                            <p class="p-profit">
                              (1950 руб. за шт. вместо 3725, выгода - 1775 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Капсулы Супер Омега, 1 шт.
                            <p class="p-profit">
                              (2349 руб. за шт. вместо 4235, выгода - 1886 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Капсулы Гриб Рейши, 1 шт.
                            <p class="p-profit">
                              (2350 руб. за шт. вместо 4305, выгода - 1955 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Капсулы ПРО 12 (симбиотик), 1 шт.
                            <p class="p-profit">
                              (2640 руб. за шт. вместо 4685, выгода - 2045 руб.
                              за шт.)
                            </p>
                          </li>
                        </ul>
                        <p class="mt-5 mb-3">
                          <a
                            href="/metabolism"
                            target="_blanc"
                            class="a-green"
                          >
                            Подробнее о наборе "Обмен веществ"
                          </a>
                        </p>
                        <p class="mt-10 mb-3 text-center p-special">
                          Спец. цена:
                        </p>
                        <v-divider
                          class="start-divider mx-auto"
                          color="#baa334"
                          length="195px"
                          thickness="3"
                        />
                        <p class="start-price text-center">14 959 руб.</p>
                        <v-divider
                          class="start-divider mx-auto"
                          color="#baa334"
                          length="195px"
                          thickness="3"
                        />
                        <p class="text-center p-old mt-3">
                          Вместо:
                          <span>
                            27 945
                            <img
                              src="/img/red-double.png"
                              class="red-double-img"
                            />
                          </span>
                        </p>
                        <p class="text-center p-old mt-1">
                          Выгода:
                          <span>
                            12 986
                            <img
                              src="/img/red-bottom.png"
                              class="red-bottom-img"
                            />
                          </span>
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <p class="p-title text-center mt-10 mb-7">
                    бонусы от <span class="lr-color">LR</span>
                  </p>
                  <ul class="ul-section">
                    <li>
                      <v-icon color="green" size="small">mdi-plus</v-icon>
                      Скидка на стартовый набор от 46% (экономия ~ 13 000 руб)
                    </li>
                    <li>
                      <v-icon color="green" size="small">mdi-plus</v-icon>
                      Скидка 28% на весь ассортимент компании LR
                    </li>
                    <li>
                      <v-icon color="green" size="small">mdi-plus</v-icon>
                      Бесплатную доставку до двери
                    </li>
                    <li>
                      <v-icon color="green" size="small">mdi-plus</v-icon>
                      Комплексное решение
                    </li>
                    <li>
                      <v-icon color="green" size="small">mdi-plus</v-icon>
                      Доступ к акции "Добро пожаловать"
                    </li>
                  </ul>
                </div>
                <div class="text-center mt-10">
                  <v-btn
                    class="mb-5 card-btn"
                    variant="elevated"
                    color="green"
                    @click="scrollToContacts()"
                  >
                    Подробнее
                  </v-btn>
                </div>
              </section>
              <section id="biz-start" class="mt-10">
                <h2 class="h2-start">Наборы с максимальной скидкой</h2>
                <div class="px-2">
                  <h3 class="h3-start text-center">Ты получаешь:</h3>
                  <!-- <p class="p-title text-center">Набор печатных материалов</p>
                  <p class="text-center">
                    каталог, карьерный план, презентация и др.
                  </p>
                  <div class="prof-start-img-books">
                    <img
                      src="/img/start/first-order-books.png"
                      alt="Печатные материалы"
                    />
                  </div> -->
                  <p class="p-title text-center mt-10 mb-7">
                    Один из наборов на выбор
                  </p>
                  <v-expansion-panels class="exp-start" popout>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="epx-title">
                        Набор "Анти-эйдж" х3
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="anti-age-start-img">
                          <img
                            src="/img/start/anti-age-x3.jpg"
                            alt="Набор Анти-эйдж"
                          />
                        </div>
                        <p>
                          Нормализует обмен веществ, дает больше энергии,
                          укрепляет здоровье, восстанавливает работу ЖКТ
                        </p>
                        <p class="p-title-green text-center mt-3 mb-1">
                          Состав набора:
                        </p>
                        <ul class="ul-exp">
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Питьевой гель Алоэ Вера Персик, 9 шт.
                            <p class="p-profit">
                              (1790 руб. за шт. вместо 3665, выгода - 1875 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Антистресс напиток Майнд Мастер "Красная формула", 9
                            шт.
                            <p class="p-profit">
                              (850 руб. за шт. вместо 1709, выгода - 859 руб. за
                              шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Антистресс напиток Майнд Мастер "Зеленая формула", 6
                            шт.
                            <p class="p-profit">
                              (850 руб. за шт. вместо 1709, выгода - 859 руб. за
                              шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Минеральный комплекс "Пробаланс", 3 шт.
                            <p class="p-profit">
                              (1850 руб. за шт. вместо 3725, выгода - 1875 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Капсулы Супер Омега, 3 шт.
                            <p class="p-profit">
                              (2272 руб. за шт. вместо 4235, выгода - 1963 руб.
                              за шт.)
                            </p>
                          </li>
                        </ul>
                        <p class="mt-5 mb-3">
                          <a
                            href="/anti-age"
                            target="_blanc"
                            class="a-green"
                          >
                            Подробнее о наборе "Анти-эйдж"
                          </a>
                        </p>
                        <p class="mt-10 mb-3 text-center p-special">
                          Спец. цена:
                        </p>
                        <v-divider
                          class="start-divider mx-auto"
                          color="#baa334"
                          length="195px"
                          thickness="3"
                        />
                        <p class="start-price text-center">41 225 руб.</p>
                        <v-divider
                          class="start-divider mx-auto"
                          color="#baa334"
                          length="195px"
                          thickness="3"
                        />
                        <p class="text-center p-old mt-3">
                          Вместо:
                          <span>
                            82 500
                            <img
                              src="/img/red-double.png"
                              class="red-double-img"
                            />
                          </span>
                        </p>
                        <p class="text-center p-old mt-1">
                          Выгода:
                          <span>
                            41 275
                            <img
                              src="/img/red-bottom.png"
                              class="red-bottom-img"
                            />
                          </span>
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                    <v-expansion-panel>
                      <v-expansion-panel-header class="epx-title">
                        Набор "Обмен веществ" х3
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <div class="metabolism-x3-img">
                          <img
                            src="/img/start/metabolism-x3.jpg"
                            alt="Набор Обмен веществ"
                            class="mr-3"
                          />
                        </div>
                        <p>
                          Комплексное решение, которое поможет нормализовать
                          обмен веществ, работу ЖКТ, кислотно-щелочной баланс,
                          укрепит иммунитет, восстановит работу внутренних
                          органов, иммунной, эндокринной системы, защитит от
                          простудных заболеваний и от стресса.
                        </p>
                        <p class="p-title-green text-center mt-3 mb-1">
                          Состав набора:
                        </p>
                        <ul class="ul-exp">
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Питьевой гель Алоэ Вера Персик или Мед, 9 шт.
                            <p class="p-profit">
                              (1790 руб. за шт. вместо 3665, выгода - 1875 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Минеральный комплекс "Пробаланс", 3 шт.
                            <p class="p-profit">
                              (1850 руб. за шт. вместо 3725, выгода - 1875 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Капсулы Супер Омега, 3 шт.
                            <p class="p-profit">
                              (2272 руб. за шт. вместо 4235, выгода - 1963 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Капсулы Гриб Рейши, 3 шт.
                            <p class="p-profit">
                              (2130 руб. за шт. вместо 4305, выгода - 2175 руб.
                              за шт.)
                            </p>
                          </li>
                          <li>
                            <v-icon color="green" size="small">mdi-check</v-icon>
                            Капсулы ПРО 12 (симбиотик), 3 шт.
                            <p class="p-profit">
                              (2340 руб. за шт. вместо 4685, выгода - 2345 руб.
                              за шт.)
                            </p>
                          </li>
                        </ul>
                        <p class="mt-5 mb-3">
                          <a
                            href="/metabolism"
                            target="_blanc"
                            class="a-green"
                          >
                            Подробнее о наборе "Обмен веществ"
                          </a>
                        </p>
                        <p class="mt-10 mb-3 text-center p-special">
                          Спец. цена:
                        </p>
                        <v-divider
                          class="start-divider mx-auto"
                          color="#baa334"
                          length="195px"
                          thickness="3"
                        />
                        <p class="start-price text-center">41 885 руб.</p>
                        <v-divider
                          class="start-divider mx-auto"
                          color="#baa334"
                          length="195px"
                          thickness="3"
                        />
                        <p class="text-center p-old mt-3">
                          Вместо:
                          <span>
                            83 835
                            <img
                              src="/img/red-double.png"
                              class="red-double-img"
                            />
                          </span>
                        </p>
                        <p class="text-center p-old mt-1">
                          Выгода:
                          <span>
                            41 950
                            <img
                              src="/img/red-bottom.png"
                              class="red-bottom-img"
                            />
                          </span>
                        </p>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                  <p class="p-title text-center mt-10 mb-7">
                    бонусы от <span class="lr-color">LR</span>
                  </p>
                  <ul class="ul-section">
                    <li>
                      <v-icon color="green" size="small">mdi-plus</v-icon>
                      Скидка на стартовый набор 50% (экономия ~42 000 руб)
                    </li>
                    <li>
                      <v-icon color="green" size="small">mdi-plus</v-icon>
                      Скидка 28% на весь ассортимент компании LR
                    </li>
                    <li>
                      <v-icon color="green" size="small">mdi-plus</v-icon>
                      Бесплатную доставку до двери
                    </li>
                    <li>
                      <v-icon color="green" size="small">mdi-plus</v-icon>
                      Комплексное решение
                    </li>
                    <li>
                      <v-icon color="green" size="small">mdi-plus</v-icon>
                      Доступ к акции "Добро пожаловать"
                    </li>
                  </ul>
                </div>
                <div class="text-center mt-10">
                  <v-btn
                    class="mb-5 card-btn"
                    variant="elevated"
                    color="green"
                    @click="scrollToContacts()"
                  >
                    Подробнее
                  </v-btn>
                </div>
              </section>

              <section id="client-start" class="mt-10">
                <h2 class="h2-start">Старт на любую продукцию</h2>
                <v-row class="px-2">
                  <v-col cols="12" sm="9">
                    <h3 class="h3-start">Ты получаешь:</h3>
                    <ul class="ul-section">
                      <li>
                        <v-icon color="green" size="small">mdi-plus</v-icon>
                        Скидка 28% на весь ассортимент компании LR
                      </li>
                      <li>
                        <v-icon color="green" size="small">mdi-plus</v-icon>
                        Бесплатную доставку до двери
                      </li>
                      <li>
                        <v-icon color="green" size="small">mdi-plus</v-icon>
                        Персональное сопровождение по продукту
                      </li>
                    </ul>
                    <p class="mt-10 mb-3 p-mobile">
                      Любая продукция из интернет-магазина LR на сумму:
                    </p>
                    <!-- <v-divider
                      class="start-divider"
                      color="#baa334"
                      length="215px"
                      thickness="3"
                    /> -->
                    <p class="start-price">от 6 500 руб.</p>
                    <!-- <v-divider
                      class="start-divider"
                      color="#baa334"
                      length="215px"
                      thickness="3"
                    /> -->
                    <div class="div-mobile mt-7">
                      <v-btn
                        class="mb-5 card-btn"
                        variant="elevated"
                        color="green"
                        @click="scrollToContacts()"
                      >
                        Подробнее
                      </v-btn>
                    </div>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <img
                      src="/img/start/e-shop-mobile.png"
                      alt="Клиентский старт"
                      class="client-start-img"
                    />
                  </v-col>
                </v-row>
              </section>

              <!-- <v-divider
                class="border-divider mt-15 mx-auto"
                color="#baa334"
                length="170px"
                thickness="2"
              />
              <v-divider
                class="border-divider mt-1 mx-auto"
                color="#baa334"
                length="100px"
                thickness="2"
              /> -->
              <div class="text-center mt-10" id="contacts">
                <h3 class="h3-contacts">
                  За подробной консультацией по наборам и условиям напишите мне
                </h3>
                <v-icon
                  icon="mdi-chevron-triple-down"
                  size="x-large"
                  color="green"
                  class="mt-5"
                ></v-icon>
                <div class="mt-12 mb-7">
                  <div class="d-flex grow flex-wrap">
                        <div
                            class="contact-avatar v-avatar mx-auto v-card--material__avatar elevation-6 grey"
                            style="height: 128px; min-width: 128px; width: 128px"
                        >
                            <div class="v-image v-responsive theme--light">
                                <img v-if="userData.avatar"
                                     :src="`${ $store.state.serverPath }/storage/${ userData.avatar }`"
                                     alt=""
                                >
                                <div class="v-responsive__content" style="width: 250px"></div>
                            </div>
                        </div>

                        <v-card-text class="text-center">
                            <h4 class="display-2 font-weight-light mb-3 black--text">
                                {{ userData.name }} {{ userData.last_name }}
                            </h4>
                        </v-card-text>
                    </div>
                    <v-row>
                        <v-col class="text-center">
                            <div class="input-group mb-3">
                                <v-tooltip
                                    top
                                    v-if="userData.phone_whatsapp"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`https://wa.me/${userData.phone_whatsapp}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="success"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>mdi-whatsapp</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в WhatsApp</span>
                                </v-tooltip>

                                <v-tooltip
                                    top
                                    v-if="userData.telegram"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <a
                                            style="text-decoration: none"
                                            :href="`${userData.telegram}`"
                                            target="_blank"
                                        >
                                            <v-btn
                                                fab
                                                dark
                                                small
                                                class="mr-4"
                                                color="blue"
                                                v-bind="attrs"
                                                v-on="on"
                                            >
                                                <v-icon>fab fa-telegram-plane</v-icon>
                                            </v-btn>
                                        </a>
                                    </template>
                                    <span>Связаться в Telegram</span>
                                </v-tooltip>
                            </div>
                        </v-col>
                    </v-row>
                </div>
              </div>
            </div>
        </v-responsive>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
    name: "StartClients",
    data: () => ({
        cookieShow: true,
    }),
    metaInfo: {
        title: 'Стартовые наборы от LR',
        meta: [
            { vmid: 'description', property: 'description', content: 'Стартовые наборы от компании LR с максимальной скидкой до 50%' },
            { vmid: 'og:title', property: 'og:title', content: 'Стартовые наборы от LR' },
            { vmid: 'og:description', property: 'og:description', content: 'Стартовые наборы от компании LR с максимальной скидкой до 50%' },
            { vmid: 'og:image', property: 'og:image', content: '' },
        ],
    },
    created() {
        if (!this.$route.query.p) {
            if (this.$cookies.isKey('lifeplus_partner')) {
                const partnerNum = this.$cookies.get('lifeplus_partner')
                this.$router.push ({name: 'StartClients', query: { p: partnerNum }})
            }
        } else {
            this.$cookies.set('lifeplus_partner', this.$route.query.p, '6m')
        }

        if (this.$cookies.isKey('lifeplus_cookie')) {
            this.cookieShow = false
        }
    },
    computed: {
        ...mapGetters({
            userData: 'user/getPartnerAccess'
        }),
    },
    methods: {
        ...mapActions({
            addNotification: 'application/addNotification',
            showPartnerAccess: 'user/showPartnerAccess',
        }),
        cookieAccept() {
            this.cookieShow = false
            this.$cookies.set('lifeplus_cookie', 'accept', '12m')
        },
        scrollToContacts () {
            let scrollBottom = document.getElementById("contacts")
            scrollBottom.scrollIntoView({ behavior: "smooth" })
        },
    },
    mounted () {
        this.showPartnerAccess(this.$route.query.p)
    },
}
</script>

<style lang="scss" scoped>
.contact-avatar {
  top:-30px;
  bottom:0;
}
.app-fill-height {
  font-family: Roboto,sans-serif;
  font-size: 16px !important;
}
ul li {
  margin-bottom: 5px;
}
.htest-h1 {
  font-size: 36px;
  text-transform: uppercase;
  color: #4caf50;
}
.p-18 {
  font-size: 18px;
}
.p-profit {
  font-size: 14px;
  text-decoration: underline;
}
.card-btn {
  padding-left: 20px;
  padding-right: 20px;
}
.h2-start {
  text-transform: uppercase;
  text-align: center;
  background-color: #4caf50;
  color: #fff;
  padding: 5px;
  margin-bottom: 30px;
  letter-spacing: 2px;
}
.h3-start {
  text-transform: uppercase;
  color: #4caf50;
  margin-bottom: 20px;
}
.h3-contacts {
  text-transform: uppercase;
  color: #333333;
}
.ul-section li {
  font-size: 18px;
  list-style-type: none;
}
.ul-section li {
  padding-left: 30px;
  position: relative;
  padding-bottom: 10px;
}
.ul-section li .v-icon {
  position: absolute;
  top: 2px;
  left: 0;
}
.start-price {
  font-size: 32px;
  font-weight: bold;
  padding: 10px 13px;
}
.start-divider {
  opacity: 1 !important;
}
.client-start-img {
  max-width: 180px;
  width: 100%;
}
.p-title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}
.prof-start-img-books {
  text-align: center;
  margin: 20px 0;
}
.prof-start-img-books img {
  max-width: 300px;
  width: 100%;
}
.exp-start {
  max-width: 800px;
  margin: 0 auto;
}
.epx-title {
  font-size: 18px;
}
.anti-age-start-img,
.metabolism-start-img {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.anti-age-start-img img {
  width: clamp(200px, 50vw, 300px);
}
.metabolism-start-img img {
  width: clamp(260px, 50vw, 600px);
}
.metabolism-x3-img img {
  width: clamp(260px, 50vw, 600px);
}

.anti-age-start-x3-img,
.metabolism-x3-img {
  position: relative;
  text-align: center;
  margin-bottom: 20px;
}
.p-title-green {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
  color: #4caf50;
}
.ul-exp {
  list-style-type: none;
}
.ul-exp li {
  position: relative;
  padding-left: 25px;
  padding-bottom: 15px;
}
.ul-exp li .v-icon {
  width: 20px;
  position: absolute;
  top: 2px;
  left: 0;
}
.a-green {
  color: #4caf50;
}
.a-green:hover {
  color: #baa334;
}
.lr-color {
  color: #4caf50;
}
.prorelife-color {
  color: #baa334;
}
.p-special {
  font-size: 22px;
  color: #c00;
  font-weight: bold;
  text-transform: uppercase;
}
.p-old {
  font-size: 22px;
  font-weight: bold;
}
.p-old span {
  position: relative;
  font-size: 26px;
  margin-left: 10px;
}
.red-double-img {
  position: absolute;
  width: 100px;
  left: -10px;
  top: 1px;
}
.red-bottom-img {
  position: absolute;
  width: 100px;
  left: -10px;
  bottom: -7px;
}
@media (max-width: 600px) {
  .htest-h1 {
    font-size: 28px;
  }
  .client-start-img {
    display: none;
  }
  .h3-start {
    text-align: center;
  }
  .start-price {
    text-align: center;
  }
  .p-mobile,
  .div-mobile {
    text-align: center;
  }
  .start-divider {
    margin: 0 auto;
  }
}
</style>